/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../../vendor/oi/ux/assets/scss/spacing.scss');
require('../../vendor/oi/ux/assets/scss/border.scss');
require('../../vendor/oi/ux/assets/scss/square.scss');
require('../scss/frontend.scss');


$(document).ready(function () {
    // reveal section on scroll function. Add class "reveal-section" to activate it on a particular section
    function reveal() {
        var reveals = document.querySelectorAll(".reveal-section");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < windowHeight) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    reveal();

    window.addEventListener("scroll", reveal);

        $("div.image-1").mouseover(function () {
            $("div.div-principale").removeClass('one two three').addClass('one');
        })
        $("div.image-2").mouseover(function () {
            $("div.div-principale").removeClass('one two three').addClass('two');
        })
        $("div.image-3").mouseover(function () {
            $("div.div-principale").removeClass('one two three').addClass('three');
        })

        $("svg .marker-map").mousemove(function (evt) {
            let country = $(this).data('country');

            let tooltip = $('.map-tooltip[data-tooltip-country="' + country + '"]')
            tooltip.show();
            tooltip.css({
                'left': evt.pageX,
                'top': evt.pageY
            });
        })

        $("svg .marker-map").mouseenter(function (evt) {
            let country = $(this).data('country');

            let tooltip = $('.map-tooltip[data-tooltip-country="' + country + '"]')
            tooltip.show();
            tooltip.css({
                'left': evt.pageX,
                'top': evt.pageY
            });
        })

        $("svg .marker-map").mouseout(function () {
            $('.map-tooltip').hide();
        })


});



